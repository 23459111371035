import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import Posts from '../components/Posts'
import Projects from '../components/Projects'
import SEO from '../components/SEO'
import Blurb from '../components/Blurb'
import SimpleListing from '../components/SimpleListing'

import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'

import projects from '../data/projects'
import interviews from '../data/interviews'
import speaking from '../data/speaking'
import featuredCertifications from '../data/featuredCertifications'

export default function BlogIndex({ data }) {
  const latest = data.latest.edges
  const popular = data.popular.edges
  const simplifiedLatest = useMemo(() => getSimplifiedPosts(latest), [latest])
  const simplifiedPopular = useMemo(() => getSimplifiedPosts(popular), [
    popular,
  ])

  const Section = ({ title, children, button, ...props }) => (
    <section {...props}>
      <h2>
        {title}
        {button && (
          <Link className="section-button" to="/blog">
            View all
          </Link>
        )}
      </h2>
      {children}
    </section>
  )

  const Section2 = ({ title, children, button2, ...props }) => (
    <section {...props}>
      <h2>
        {title}
        {button2 && (
          <Link className="section-button" to="/certificates">
            View all
          </Link>
        )}
      </h2>
      {children}
    </section>
  )

  return (
    <Layout>
      <SEO customDescription="Articles, tutorials, snippets, musings, and everything else." />
      <header>
        <div className="container">
          <h1>Jason Yonathan Huang</h1>
          <p className="subtitle">
            Hello World! Welcome to my website.
          </p>
          <p>here you can find more information about me and some (might be useful) articles 😃😃          
          </p>
        </div>
      </header>
      <Helmet title={config.siteTitle} />
      <SEO />
      <div className="container index">
        <Section title="Latest Articles" button>
          <Posts data={simplifiedLatest} />
        </Section>
        <Section title="Popular Articles" button>
          <Posts data={simplifiedPopular} />
        </Section>
        <Section title="Projects">
          <Projects data={projects} />
        </Section>
        <Section2 title="Licenses & Certifications" button2>
          <SimpleListing data={featuredCertifications} />
        </Section2>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 20
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
